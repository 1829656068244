import React, { useState } from 'react'
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./speaker.css"

import ImageFadeIn from "react-image-fade-in";

  function handleClick(name, surname){
    if(window.analytics){
      window.analytics('send', 
        {
          hitType: 'event',
          eventCategory: 'speakers',
          eventAction: 'click',
          eventLabel: 'openbio ' + name + ` ` + surname
        }
      );
    }
  }



const Speakers = (props) => {

  const [collapsed, setCollapsed] = useState(true);

  const data = props.data;
  const id= props.dataId;



  return (
      <div
          role="button"
          tabIndex={0}
          className="speaker" 
          data-id={id} 
          id={data.id}
          onKeyDown={()=>{
            setCollapsed(!collapsed)
            handleClick(data.name, data.surname)
          }}
          onClick={()=>{
            setCollapsed(!collapsed)
            handleClick(data.name, data.surname)
          }}
      >

        <div className="row speaker-row">

          <div className="col-lg-2 col-md-3 col-sm-3 hidden-print justify-content-center justify-content-sm-start" id={data.name + `` + data.surname}> 
            <div className="mb-3">
                  <ImageFadeIn 
                    className="img-fluid mb-3 speaker-img" 
                    src={`../images/speakers/s${data.id}.jpg`} 
                    alt={data.name + ` ` + data.surname}
                  />
              </div>
          </div>

          <div className={"col-lg-10 col-md-9 col-sm-9 mt-sm-0 pt-2 pl-sm-4 mb-3" + ((collapsed)?" details-collapsed":"")}>
            <div className={"col-xs-0 col-sm-11 col-lg-10 p-0"}>
              <p className="h5 mb-2 akkurat-pro-bold color-secondary font-weight-bold">{data.name + ` ` + data.surname}</p>
              <p className="h5 publico-text-web color-secondary mb-3 d-block" dangerouslySetInnerHTML={{__html: data.job + `, ` + data.organisation }} />
            </div>
            <div className={"details col-xs-0 col-sm-11 col-lg-10 p-0 mb-3"}>
            {
              data.about.map((d,i) => ( <p key={i} data-id={i} className="akkurat-pro color-secondary line-height-12 mb-2" dangerouslySetInnerHTML={{__html: d }} /> ))
            }
            </div>
          </div>

        </div>

        <div className={"row position-relative m-0 mb-2 mb-xs-4" + ((collapsed)?" mt-n2":" mt-n2")} >
          <div className="details-hr"/>
          <div className="toggle-details row mt-n2 justify-content-end">
            <span className="mr-3 color-secondary">{((collapsed)?"Show more":"Hide")}</span> 
            <FontAwesomeIcon color="#00485E" icon={((collapsed)?faChevronDown:faChevronUp)} size="lg"/>
          </div>
        </div>
      </div>
  )
}
export default Speakers