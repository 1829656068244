import React, { useState } from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Speaker from '../components/speaker'
import { smoothScrollBackToTop } from '../utils/index.js'

import '../styles/speakers.css'

import Player from "../components/player"

import datas from '../data/data-speakers.json';

import _filter from 'lodash/filter';
import _orderBy from 'lodash/orderBy';



let keynote = _filter(datas.speakers, function(d) { return d.type==="0" });
let speakers = _filter(datas.speakers, function(d) { return d.type==="1" });
let ambassadors = _filter(datas.speakers, function(d) { return d.type==="2" });

let keynoteSorted =  _orderBy(keynote, ['surname'],['asc']); 
let speakersSorted =  _orderBy(speakers, ['surname'],['asc']); 
let ambassadorsSorted =  _orderBy(ambassadors, ['surname'],['asc']); 

const Speakers = () => {
  const [show, setShow] = useState(-1);

return (
  <>
  <Layout>
    <SEO title="Speakers" description="BNY Mellon’s Future FirstSM Forum brings together ESG industry experts, academics and BNY Mellon thought leaders to discuss how the industry is working, investing and collaborating to safeguard a more sustainable future."/>

        <div className="back-bg d-block" />
        <div className="back-bg2 d-block" />

    <div id="speakers" className="container-fluid bg-softblue">
      <div className="container">

        <div className="mb-5 d-none d-lg-block" />

        <div className="row pt-5 pb-5">
          <div className="col-lg-7">
            <h1 className="publico-text-web display-4 mb-3 color-blue">{`Our Speakers`}</h1>
            <p className="h5-small mb-0 color-blue">The <span className="superitalic">Future First<sup>SM</sup></span> Forum brings together industry experts, academics and BNY Mellon thought leaders to discuss how the industry is working, investing and collaborating to safeguard a more sustainable future.</p>
          </div>

          <div className="col-lg-5 d-flex justify-content-end align-items-end">

            <a 
            rel="noreferrer"
              id="watch-intro" 
              href="/pdf/F3-RecapArticle.PDF"
              className="btn btn-darkteal btn-lg watch-intro medium"
              onClick={(e)=>{
                // e.preventDefault();
              if(window.analytics){
                window.analytics('send', 
                  {
                    hitType: 'event',
                    eventCategory: 'speakers',
                    eventAction: 'click',
                    eventLabel: 'RECAP ARTICLE'
                  }
                );
              }
              }}
              target="_blank" 
            >RECAP ARTICLE</a>

          </div>

        </div>

        <div className="mb-5 d-none d-lg-block" />


        <p className="h5 mb-4 color-blue position-relative">Keynote Spotlight</p>

        {
          keynoteSorted.map((d,i) => (
              <Speaker
                key={`k`+i}
                dataId={i}
                data={d}
               />
            ))
        }

        <p className="h5 mt-sp mb-4 color-blue position-relative">Speakers</p>

        {
          speakersSorted.map((d,i) => (
              <Speaker
                key={`s`+i}
                dataId={i}
                data={d}
               />
            ))
        }

        <p className="h5 mt-sp mb-4 color-blue position-relative"><i>Future First<sup>SM</sup></i> Forum Ambassadors</p>

        {
          ambassadorsSorted.map((d,i) => (
              <Speaker
                key={`a`+i}
                dataId={i}
                data={d}
               />
            ))
        }



              <p className="h4-small mt-5 mb-0 color-blue medialink" dangerouslySetInnerHTML={{__html: `For media inquiries, please view our <a href="https://www.bnymellon.com/us/en/about-us/newsroom/media-resources.html" target="_blank">Media Resources</a>` }} />




        <div className="d-flex justify-content-center position-relative">
          <a 
          id="back-to-top" 
          href="/#" 
          className="btn btn-darkteal btn-lg back-to-top medium mt-17 mb-17 pl-5 pr-5"

            onClick={(e)=>{
              e.preventDefault();
              smoothScrollBackToTop();
              if(window.analytics){
                window.analytics('send', 
                  {
                    hitType: 'event',
                    eventCategory: 'speakers',
                    eventAction: 'click',
                    eventLabel: 'totop'
                  }
                );
              }
            }}

          >Back to top</a>
        </div>

      </div>
    </div>
  </Layout>
     <Player UUID={show} setShow={setShow}/>
    </>

  )
}

export default Speakers